<template>
  <v-form ref="formRef" class="form relative">
    <v-overlay
      :value="statusRequest.value === 'loading-getUtmBuilderConfig'"
      absolute
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div v-if="utmBuilderConfig">
      <div>
        <div class="font-weight-bold text-body-1">Website URL *</div>
        <v-textarea
          v-if="isInputUrl"
          v-model="listInternalUrl"
          class="utm-builder-input text-body-1"
          outlined
          :rules="[
            v => !!v || 'Vui lòng nhập url',
            v => verifyUrlRule(v) || 'URL không hợp lệ'
          ]"
          placeholder="Nhập 1 hoặc nhiều url"
        >
        </v-textarea>

        <v-text-field
          v-else
          :value="url"
          class="utm-builder-input text-body-1"
          dense
          outlined
          readonly
        >
          <template v-slot:append>
            <v-btn icon @click="copyToClipboard(url)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>

      <div class="d-flex">
        <div class="mr-6 flex-1">
          <div class="font-weight-bold text-body-1 d-flex">
            Source *
          </div>

          <v-combobox
            v-model="source"
            :items="utmBuilderConfig.source"
            dense
            outlined
            :rules="[v => !!v || 'Vui lòng nhập url']"
          ></v-combobox>
        </div>

        <div class="flex-1">
          <div class="font-weight-bold text-body-1">Medium *</div>

          <v-combobox
            v-model="medium"
            :items="utmBuilderConfig.medium"
            dense
            outlined
            :rules="[v => !!v || 'Vui lòng nhập url']"
          ></v-combobox>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-6 flex-1">
          <div class="font-weight-bold text-body-1">Người phụ trách</div>

          <v-combobox
            v-model="name"
            :items="utmBuilderConfig.name"
            dense
            outlined
          ></v-combobox>
        </div>

        <div class="flex-1">
          <div class="font-weight-bold text-body-1">Ngày đăng</div>

          <tp-input-date
            v-model="date"
            clearable
            dense
            outlined
          ></tp-input-date>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-6 flex-1">
          <div class="font-weight-bold text-body-1">Sản phẩm</div>

          <tp-text-field v-model="product" class="text-body-1" dense outlined>
          </tp-text-field>
        </div>

        <div class="flex-1">
          <div class="font-weight-bold text-body-1">Nội dung</div>

          <v-text-field
            v-model="content"
            :rules="[wordCountRule]"
            dense
            outlined
          ></v-text-field>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-6 flex-1">
          <div class="font-weight-bold text-body-1">Domain *</div>

          <tp-autocomplete
            v-model="domain"
            :items="utmBuilderConfig.domain"
            dense
            outlined
            validate="required"
          ></tp-autocomplete>
        </div>

        <div class="flex-1"></div>
      </div>

      <v-divider class="my-3"></v-divider>

      <div v-if="isVisible">
        <div>
          <div class="font-weight-bold text-body-1">Campaign URL</div>

          <v-textarea
            :value="generateUrl()"
            class="text-body-1"
            no-resize
            outlined
            readonly
          >
            <template v-slot:append>
              <v-btn icon @click="copyToClipboard(generateUrl())">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-textarea>
        </div>

        <div
          class="d-flex align-baseline"
          v-bind:class="{ 'flex-column': isInputUrl }"
        >
          <v-btn
            :loading="statusRequest.value === 'loading-getShortURL'"
            class="mr-3"
            color="primary"
            depressed
            @click="handleShortenLink"
            >Rút gọn link
          </v-btn>

          <div v-if="!isInputUrl" class="flex-1">
            <v-text-field
              :value="shortenUrlResponse[0].link || ''"
              class="utm-builder-input text-body-1"
              dense
              hide-details
              label="Link rút gọn"
              outlined
              readonly
            >
              <template v-if="shortenUrlResponse[0].link" v-slot:append>
                <v-btn
                  icon
                  @click="copyToClipboard(shortenUrlResponse[0].link)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-btn
              :href="shortenUrlPage"
              class="button-link"
              color="primary"
              link
              small
              target="_blank"
              text
              >Danh sách link đã tạo
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

          <div
            v-if="isInputUrl && dataTable && dataTable.length"
            v-bind:style="{ width: '100%' }"
            class="mt-6"
          >
            <v-btn class="mb-3" icon @click="copyToClipboard(meta)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>

            <v-data-table
              :headers="headers"
              :items="dataTable"
              hide-default-header
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item.copyLink="{ item }">
                <v-btn
                  v-if="dataTable && dataTable[0].link"
                  icon
                  @click="copyToClipboard(item.link)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.copyTarget="{ item }">
                <v-btn
                  v-if="dataTable && dataTable[0].target"
                  icon
                  @click="copyToClipboard(item.target)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
            </v-data-table>

            <div class="d-flex justify-end mt-2">
              <v-btn
                :href="shortenUrlPage"
                color="primary"
                link
                small
                target="_blank"
                text
                >Danh sách link đã tạo
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="mt-5 text-center font-weight-bold">
        Điền vào các trường bắt buộc ở trên và URL sẽ được tạo tại đây.
        <v-btn
          :href="shortenUrlPage"
          color="primary"
          link
          small
          target="_blank"
          text
          >Danh sách link đã tạo
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import dayjs from "dayjs";
import { withQuery } from "ufo";
import {
  toKebabCase,
  removeVietnameseTones,
  toLowerCase,
  join
} from "@vt7/utils";
import { regexUrl } from "@/core/utils/regex";

export default {
  props: {
    url: {
      type: String,
      default: ""
    },
    isInputUrl: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formRef: null,
      source: "",
      medium: "",
      name: "",
      product: "",
      date: "",
      content: "",
      domain: "",
      listInternalUrl: "",
      wordCountRule: value => {
        const words = value.trim().split(" ");

        if (words.length > 5) {
          return "Không nhập quá 5 chữ";
        }
        return true;
      },
      shortenUrlPage: process.env.VUE_APP_SHORTEN_URL_PAGE,
      headers: [
        {
          text: "Link",
          value: "link"
        },
        { text: "Copy link", value: "copyLink" },
        { text: "Target", value: "target" },
        { text: "Copy target", value: "copyTarget" }
      ]
    };
  },

  created() {
    this.$store.dispatch("UTM_BUILDER/getUtmBuilderConfig");
  },

  computed: {
    formatListUrl() {
      return (
        this.listInternalUrl.split(/[\s,]+/).filter(item => item !== "") || []
      );
    },

    isVisible() {
      const hasRequiredFields =
        this.source &&
        this.medium &&
        this.wordCountRule(this.content) === true &&
        this.domain &&
        (this.formatListUrl.length || this.url);

      if (this.isInputUrl) {
        return hasRequiredFields && this.verifyUrlRule(this.listInternalUrl);
      } else {
        return hasRequiredFields;
      }
    },

    shortenUrlResponse() {
      return this.$store.getters["UTM_BUILDER/shortenUrlResponse"];
    },

    meta() {
      return this.$store.getters["UTM_BUILDER/meta"];
    },

    statusRequest() {
      return this.$store.getters["UTM_BUILDER/statusRequest"];
    },

    utmBuilderConfig() {
      return this.$store.getters["UTM_BUILDER/utmBuilderConfig"];
    },

    campaign() {
      const name = toLowerCase(this.name);
      const date = this.date ? dayjs(this.date).format("DDMM") : "";
      const product = toKebabCase(this.product);
      const _campaign = [name, date, product];
      return join(_campaign, "-");
    },

    dataTable() {
      return this.shortenUrlResponse.map(item => {
        return {
          target: item.target,
          link: item.link
        };
      });
    }
  },

  methods: {
    convertToKebabCase(value) {
      return toKebabCase(removeVietnameseTones(value));
    },

    async copyToClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã sao chép vào bộ nhớ tạm"
          }
        });
      } catch ($e) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Không thể sao chép vào bộ nhớ tạm"
          }
        });
      }
    },

    async handleShortenLink() {
      const isValid = await this.$refs.formRef.validate();

      if (!isValid) return;

      this.$store.dispatch("UTM_BUILDER/getShortURL", {
        target: this.generateUrl(),
        domain: this.domain
      });
    },

    generateUrl() {
      let url = this.url.length ? this.url : this.formatListUrl;

      const query = {
        utm_source: this.source,
        utm_medium: this.medium,
        utm_campaign: this.campaign,
        utm_content: this.convertToKebabCase(this.content)
      };
      if (this.isInputUrl) {
        return this.formatListUrl.map(url => withQuery(url, query)).join("\n");
      } else {
        return withQuery(url, query);
      }
    },

    verifyUrlRule(value) {
      const formatValue =
        value.split(/[\s,]+/).filter(item => item !== "") || [];
      return formatValue.every(url => regexUrl(url));
    }
  },

  watch: {
    listInternalUrl: {
      handler() {
        this.$store.dispatch("UTM_BUILDER/resetData");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  height: 100%;
}

.button-link {
  position: absolute;
  right: 0;
}

.utm-builder-input {
  ::v-deep .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        margin-top: 1px;
      }
    }
  }
}
</style>
